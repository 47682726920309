var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ResourceFormImport" },
    [
      _c("div", { staticClass: "tips" }, [
        _vm._v("温馨提示：批量导入资源，请下载标准模板，以免导入错误。"),
      ]),
      _c("div", { staticClass: "cell-box" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "space-right" },
          [
            _c(
              "v-select2",
              _vm._b(
                {
                  attrs: {
                    subjoin: _vm.parentMenuSubjoin,
                    placeholder: "查询上级菜单",
                    width: 200,
                  },
                  model: {
                    value: _vm.extraData.parentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.extraData, "parentId", $$v)
                    },
                    expression: "extraData.parentId",
                  },
                },
                "v-select2",
                _vm.parentMenuParams,
                false
              )
            ),
          ],
          1
        ),
      ]),
      _c(
        "import-template",
        {
          attrs: {
            uploadUrl: _vm.importURL,
            downloadUrl: _vm.downloadUrl,
            remarkLabel: "备注：",
            saveBefore: _vm.saveBefore,
            extraData: _vm.extraData,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "remark",
              attrs: { slot: "remark" },
              slot: "remark",
            },
            [
              _c("div", [_vm._v("1、页面名称:必填;")]),
              _c("div", [
                _vm._v("2、打开方式:后台嵌入/页面重定向/新窗口,必填;"),
              ]),
              _c("div", [
                _vm._v("3、授权方式:原生/外链非授权/外链需授权,必填;"),
              ]),
              _c("div", [_vm._v("4、菜单URL:页面菜单路径,必填;")]),
              _c("div", [
                _vm._v(
                  "5、特殊编码:如果需要固定使用某个页面的时候,使用这个菜单特殊编号定位,非必填;"
                ),
              ]),
              _c("div", [
                _vm._v("6、页面功能版本号:功能对应的平台版本号,必填;"),
              ]),
              _c("div", [_vm._v("7、页面使用终端:菜单使用在哪个载体中,必填;")]),
              _c("div", [_vm._v("8、帮助文档URL:使用说明文档链接,非必填;")]),
              _c("div", [_vm._v("9、按钮名称:需要导入按钮的时候必填;")]),
              _c("div", [_vm._v("10、按钮code:需要导入按钮的时候必填;")]),
              _c("div", [
                _vm._v(
                  "11、按钮类型:可见/新增/编辑/其他,影响角色授权的显示位置,需要导入按钮的时候必填;"
                ),
              ]),
              _c("div", [
                _vm._v(
                  "12、角色归属:系统,平台,区域,项目,商家,门店,组织 可写多个用逗号隔开,需要导入按钮的时候必填;"
                ),
              ]),
              _c("div", [
                _vm._v(
                  "13、按钮特殊编码:如果需要固定使用某个按钮功能的时候,使用这个特殊编号定位,非必填;"
                ),
              ]),
              _c("div", [
                _vm._v(
                  "14、按钮功能版本号:功能对应的平台版本号,需要导入按钮的时候必填;"
                ),
              ]),
              _c("div", [
                _vm._v("15、资源URL:资源的链接地址,需要关联资源的时候必填;"),
              ]),
              _c("div", [
                _vm._v("16、资源请求方式:GET/POST等,需要关联资源的时候必填;"),
              ]),
              _c("div", [
                _vm._v(
                  "17、资源所属的平台:业主端app,物管app,管理后台,接口属于那个平台项目,需要关联资源的时候必填;"
                ),
              ]),
              _c("div", [
                _vm._v(
                  "18、权限校验:正常/关闭，只作用于绑定了菜单的资源URL。表示是否需要进行资源验证,需要关联资源的时候必填;"
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "space-left" }, [
      _c("label", [_vm._v("上级菜单：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }