<template>
  <div class="ResourceFormImport">
    <div class="tips">温馨提示：批量导入资源，请下载标准模板，以免导入错误。</div>
    <div class="cell-box">
      <div class="space-left">
        <label>上级菜单：</label>
      </div>
      <div class="space-right">
        <v-select2 v-model="extraData.parentId" v-bind="parentMenuParams" :subjoin="parentMenuSubjoin" placeholder="查询上级菜单" :width="200" />
      </div>
    </div>
    <import-template :uploadUrl="importURL" :downloadUrl="downloadUrl" remarkLabel="备注：" :saveBefore="saveBefore" :extraData="extraData">
      <div slot="remark" class="remark">
        <div>1、页面名称:必填;</div>
        <div>2、打开方式:后台嵌入/页面重定向/新窗口,必填;</div>
        <div>3、授权方式:原生/外链非授权/外链需授权,必填;</div>
        <div>4、菜单URL:页面菜单路径,必填;</div>
        <div>5、特殊编码:如果需要固定使用某个页面的时候,使用这个菜单特殊编号定位,非必填;</div>
        <div>6、页面功能版本号:功能对应的平台版本号,必填;</div>
        <div>7、页面使用终端:菜单使用在哪个载体中,必填;</div>
        <div>8、帮助文档URL:使用说明文档链接,非必填;</div>
        <div>9、按钮名称:需要导入按钮的时候必填;</div>
        <div>10、按钮code:需要导入按钮的时候必填;</div>
        <div>11、按钮类型:可见/新增/编辑/其他,影响角色授权的显示位置,需要导入按钮的时候必填;</div>
        <div>12、角色归属:系统,平台,区域,项目,商家,门店,组织 可写多个用逗号隔开,需要导入按钮的时候必填;</div>
        <div>13、按钮特殊编码:如果需要固定使用某个按钮功能的时候,使用这个特殊编号定位,非必填;</div>
        <div>14、按钮功能版本号:功能对应的平台版本号,需要导入按钮的时候必填;</div>
        <div>15、资源URL:资源的链接地址,需要关联资源的时候必填;</div>
        <div>16、资源请求方式:GET/POST等,需要关联资源的时候必填;</div>
        <div>17、资源所属的平台:业主端app,物管app,管理后台,接口属于那个平台项目,需要关联资源的时候必填;</div>
        <div>18、权限校验:正常/关闭，只作用于绑定了菜单的资源URL。表示是否需要进行资源验证,需要关联资源的时候必填;</div>
      </div>
    </import-template>
  </div>
</template>

<script>
import { ImportTemplate } from 'components/bussiness/index'
import { importURL, queryMenuListURL } from './api'

export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      importURL,
      downloadUrl: '/static/excel/资源导入模板.xlsx',
      extraData: {
        parentId: undefined
      },
      parentMenuParams: {
        searchUrl: queryMenuListURL,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      parentMenuSubjoin: {
        menuType: 1
      }
    }
  },
  created () {
    this.$setBreadcrumb('导入资源')
  },
  methods: {
    saveBefore () {
      if (!this.extraData.parentId) {
        this.extraData.parentId = 0
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.ResourceFormImport {
  .tips {
    color: red;
    background: #FFF;
    text-align: left;
    padding: 0 0 10px 58px;
  }
  .remark {
    div {
      margin-bottom: 10px;
    }
  }
  .cell-box {
    display: flex;
    background: #FFF;
    padding: 10px 20px;
    .space-left {
      min-width: 150px;
      text-align: right;
      span {
        color: red;
      }
      label {
        font-size: 14px;
        color: #333333;
        font-weight: 700;
      }
    }
    .space-right {
      padding-left: 20px;
    }
  }
  ::v-deep {
    .importTemplate-container {
      height: calc(100% - 100px);
    }
  }
}
</style>
